import * as React from "react";
import { useForm } from "react-typed-form";
import styled from "styled-components";

import { Domain, Config } from "../../../constants";
import { Color } from "../../../constants/legacy";
import { FieldText, SubmitButton, FieldSelect } from "../../../form/legacy";

type Props = {
  heading?: string;
  buttonLabel?: string;
  thanksText?: string;
};

export default function PartnerForm({
  heading,
  buttonLabel,
  thanksText,
}: Props) {
  const [success, setSuccess] = React.useState<boolean>(false);

  return (
    <StyledForm>
      <h3>{heading || "Request a personalised demo"}</h3>


    </StyledForm>
  );
}

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 50px;
  width: 100%;
  max-width: 460px;

  background-color: ${Color.darkBlue};
  color: ${Color.white};
  text-align: center;
`;

const StyledFormSpacer = styled.div`
  height: 20px;
`;

const StyledSuccess = styled.div`
  font-size: 18px;
  background-color: ${Color.cyan};
  text-align: center;
  margin-top: 40px;
  padding: 60px 20px;
`;
