import * as React from "react";
import styled from "styled-components";

import { Color } from "../../../constants/legacy";
import Col from "../Col";
import Cols from "../Cols";
import FormattedRichText from "../FormattedRichText";
import HorizontalContainer from "../HorizontalContainer";
import Section from "../Section";

type Props = {
  heading?: string;
  description: any;
  allLogos?: ReadonlyArray<
    undefined | { logo_category?: string; logo_image: any }
  >;
  logoCategory: string;
  alt?: boolean;
};

export default function SectionPartnerGroup({
  heading,
  description,
  allLogos,
  logoCategory,
  alt,
}: Props) {
  return (
    <StyledSectionPartnerGroup $alt={alt}>
      <HorizontalContainer>
        <Cols>
          <Col flex={5}>
            <h2>{heading}</h2>
            <FormattedRichText render={description} />
          </Col>
          <Col />
          <Col flex={5}>
            <StyledLogos>
              {allLogos
                ?.filter((logo) => logo?.logo_category === logoCategory)
                .map(
                  (logo) =>
                    logo && (
                      <StyledLogoWrap key={logo.logo_image.url}>
                        <div>
                          <img src={logo.logo_image.url} />
                        </div>
                      </StyledLogoWrap>
                    )
                )}
            </StyledLogos>
          </Col>
        </Cols>
      </HorizontalContainer>
    </StyledSectionPartnerGroup>
  );
}

const StyledSectionPartnerGroup = styled(Section)<{ $alt?: boolean }>`
  background-color: ${(p) => (p.$alt ? Color.white : Color.lightGrey)};
`;

const StyledLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLogoWrap = styled.div`
  box-sizing: border-box;
  width: 50%;
  height: 120px;
  padding: 10px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  div {
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding: 15px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
