import { graphql } from "gatsby";
import * as React from "react";

import { unwrapFirst } from "../util";
import {
  Layout,
  Hero,
  HelmetSeo,
  Section,
  HorizontalContainer,
  Cols,
  Col,
  FormattedRichText,
  Button,
} from "../components/legacy";
import {
  PartnerForm,
  SectionPartnerGroup,
} from "../components/legacy/partners";
import { Color } from "../constants/legacy";
import HERO_BG_LEFT from "../../img/legacy/partners/hero_bg_left.svg";
import HERO_BG_RIGHT from "../../img/legacy/partners/hero_bg_right.svg";
import HERO_BG_MOBILE_LEFT from "../../img/legacy/partners/hero_bg_mobile_left.svg";
import HERO_BG_MOBILE_RIGHT from "../../img/legacy/partners/hero_bg_mobile_right.svg";
import styled from "styled-components";
import { MaxWidth, Text } from "../components";

type Props = {
  data: GatsbyTypes.PartnersPageQuery;
};

export const query = graphql`
  query PartnersPage {
    prismicPartnersPage {
      data {
        hero_title
        hero_pre_title
        hero_subtitle {
          raw
        }
        hero_button_label

        leading_heading
        leading_text {
          raw
        }

        benefits_heading
        benefits {
          benefit_heading
          benefit_description
        }

        service_partners_heading
        service_partners_description {
          raw
        }

        business_partners_heading
        business_partners_description {
          raw
        }

        technology_alliances_heading
        technology_alliances_description {
          raw
        }

        logos {
          logo_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          logo_category
        }

        form_heading
        form_button_label
        form_thanks_text

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function PartnersPage({ data }: Props) {
  const page = data.prismicPartnersPage?.data;
  if (!page) throw new Error("page missing");

  const scrollToForm = () => {
    document
      .getElementById("form")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Layout footerBackColor={Color.blue}>
      <HelmetSeo {...page} />

      <Hero
        title={page.hero_title || ""}
        subTitle={page.hero_subtitle?.raw}
        preTitle={page.hero_pre_title}
        button={
          page.hero_button_label ? (
            <Button
              label={page.hero_button_label ?? "Apply"}
              onClick={scrollToForm}
              href="#form"
            />
          ) : undefined
        }
        preTitleColor="orange"
        center
        minHeight={380}
        customBackground={
          <>
            <StyledHeroBgLeft src={HERO_BG_LEFT} />
            <StyledHeroBgRight src={HERO_BG_RIGHT} />

            <StyledHeroBgMobileLeft src={HERO_BG_MOBILE_LEFT} />
            <StyledHeroBgMobileRight src={HERO_BG_MOBILE_RIGHT} />
          </>
        }
      />

      <Section>
        <HorizontalContainer>
          <Cols>
            <Col flex={5}>
              <StyledLeadingHeading>
                {page.leading_heading}
              </StyledLeadingHeading>
            </Col>
            <Col flex={1} />
            <Col flex={13}>
              <FormattedRichText render={page.leading_text?.raw} />
            </Col>
          </Cols>
        </HorizontalContainer>
      </Section>

      <StyledSectionBenefits>
        <HorizontalContainer>
          <h2>{page.benefits_heading}</h2>
          <StyledBenefits>
            {page.benefits?.map(
              (benefit) =>
                benefit && (
                  <StyledBenefit>
                    <div>
                      <h3>{benefit.benefit_heading}</h3>
                      <p>{benefit.benefit_description}</p>
                    </div>
                  </StyledBenefit>
                )
            )}
          </StyledBenefits>
        </HorizontalContainer>
      </StyledSectionBenefits>

      <SectionPartnerGroup
        heading={page.service_partners_heading}
        description={page.service_partners_description?.raw}
        allLogos={page.logos}
        logoCategory="service_partner"
      />

      <SectionPartnerGroup
        heading={page.business_partners_heading}
        description={page.business_partners_description?.raw}
        allLogos={page.logos}
        logoCategory="business_partner"
        alt
      />

      <SectionPartnerGroup
        heading={page.technology_alliances_heading}
        description={page.technology_alliances_description?.raw}
        allLogos={page.logos}
        logoCategory="technology_alliance"
      />

      <div id="form" />
      <StyledSectionContact>
        <HorizontalContainer>
          <StyledFormWrap>
            <MaxWidth width={560}>
              <Text as="h2" bold align="center" color="dark4">
                {page.form_heading}
              </Text>
              <iframe
                src="https://forms.wisetechglobal.com/shipamax/en-us/partner-application/"
                width="100%"
                height="850"
                frameBorder="0"
                allowTransparency={true}
                style={{ border: 0, margin: 0 }}
              />
            </MaxWidth>
          </StyledFormWrap>
        </HorizontalContainer>
      </StyledSectionContact>
    </Layout>
  );
}

const StyledHeroBgLeft = styled.img`
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: 1000px) {
    left: -20%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const StyledHeroBgRight = styled.img`
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1000px) {
    right: -20%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const StyledHeroBgMobileLeft = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  display: none;

  @media (max-width: 700px) {
    display: block;
  }
`;

const StyledHeroBgMobileRight = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  display: none;

  @media (max-width: 700px) {
    display: block;
  }
`;

const StyledLeadingHeading = styled.h2`
  font-weight: 700;
  font-size: 28px;
`;

const StyledSectionBenefits = styled(Section)`
  background-color: ${Color.blue};
  color: ${Color.white};

  h2 {
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    margin-bottom: 80px;

    @media (max-width: 1000px) {
      margin-bottom: 30px;
      font-size: 28px;
    }
  }
`;

const StyledBenefits = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledBenefit = styled.div`
  width: 33.33%;
  box-sizing: border-box;
  padding: 10px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  > div {
    height: 300px;
    padding: 26px;
    background-color: ${Color.darkBlue};

    @media (max-width: 1000px) {
      height: auto;
    }
  }

  h3 {
    color: ${Color.cyan};
    font-weight: 700;
    font-size: 22px;
  }

  p {
    font-size: 15px;
  }
`;

const StyledSectionContact = styled(Section)`
  background-color: ${Color.blue};
`;

const StyledFormWrap = styled.div`
  background-color: ${Color.white};
  border-radius: 8px;
  padding: 30px 20px 10px;
  margin: 0 auto;
  max-width: 720px;
`;
